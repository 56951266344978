/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Image } from '@swp/components'
import SiteHeader from '../../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Menu"}>
        <SiteHeader set="en" currentLanguage={1} />

        <Column className="--center pt--80" style={{"paddingBottom":0}} name={"potvrdit-ucast-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1090}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper className="pt--40">
              
              <Title className="title-box title-box--center fs--48 mt--10" content={"MENU SELECTION"}>
              </Title>

              <Text className="text-box ff--1 fs--18" style={{"maxWidth":600}} content={"We would like you to enjoy our wedding in every way,<br>please choose the menu that suits you best.\n<br><br>Please, also confirm if you need to arrange parking at the Aria Hotel.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"wsn1lkp0cme"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper className="js-anim  --anim5" anim={"5"}>
              
              <Title className="title-box fs--48" content={"<span style=\"color: var(--color-blend--50);\">MENU 1</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--50);\">PRAWN<br>wonton | XO sauce | sesame | soya<br><br>ASPARAGUS<br>Panko  | chilli  | tapioca  | asian herbs\n\n<br><br>HALIBUT<br>Spinach | shitake | miso | radish<br><br>STRAWBERRIES<br>Chutney | meringue | sorbet | chocolate</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim2" anim={"2"}>
              
              <Title className="title-box fs--48" content={"<span style=\"color: var(--color-blend--50);\">MENU 2</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--50);\">DUCK<br>Grapefruit  | edamame | ginger | ponzu\n\n<br><br>ASPARAGUS<br>Panko  | chilli  | tapioca  | asian herbs\n\n<br><br>PICANHA<br>Black peper | broccolini | coriander chilli – salsa\n\n<br><br>STRAWBERRIES<br>Chutney | meringue | sorbet | chocolate</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="js-anim  --anim4" anim={"4"}>
              
              <Title className="title-box fs--48" content={"<span style=\"caret-color: rgb(156, 147, 148); color: rgb(156, 147, 148);\">KIDS MENU</span><br>"}>
              </Title>

              <Text className="text-box text-box--center" content={"<span style=\"color: var(--color-blend--50);\">CHICKEN<br>Corn chicken schnitzel | mashed potatoes | salad &nbsp;&nbsp;<br><br>STRAWBERRIES<br>Chutney | meringue | sorbet | chocolate</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--0 pt--0" name={"potvrdit-ucast"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 ff--2 fs--16" style={{"maxWidth":780}} action={"/contact"} fields={[{"name":"Whole name guest 1","type":"text","required":true,"placeholder":""},{"type":"select","name":"Menu selection guest 1","required":true,"options":[{"id":"77e9f6wynw7s","value":"menu 1 - fish"},{"id":"69dz91ojzsvv","value":"menu 2 - meat"}],"placeholder":"Please, choose menu for guest 1"},{"type":"text","name":"Whole name guest 2"},{"type":"select","name":"Menu selection guest 2","options":[{"id":"uvbqbp6fkhxn","value":"menu 1 - fish"},{"id":"8cs6mi1yebh3","value":"menu 2 - meet"},{"id":"f8igbd1sydid","value":"kids menu"}],"placeholder":"Please, choose menu for guest 2"},{"type":"text","name":"Whole name guest 3"},{"type":"select","name":"Menu selection guest 3","options":[{"id":"w2yny9d36blr","value":"menu 1 - fish"},{"id":"cyq9b6458eqd","value":"menu 2 - meet"},{"id":"k0yu8mmxbyjn","value":"kids menu"}],"placeholder":"Please, choose menu for guest 3"},{"type":"text","name":"Whole name guest 4"},{"type":"select","name":"Menu selection guest 4","options":[{"id":"970jshd87pdf","value":"menu 1 - fish"},{"id":"ypktr5t4pk3j","value":"menu 2 - meet"},{"id":"rl9v3fklp2x6","value":"kids menu"}],"placeholder":"Please, choose menu for guest 4"},{"type":"text","name":"Whole name guest 5"},{"type":"select","name":"Menu selection guest 5","options":[{"id":"miuc3a6nbmvo","value":"menu 1 - fish"},{"id":"a6eooykb3p1b","value":"menu 2 - meet"},{"id":"bvladk8bxrw2","value":"kids menu"}],"placeholder":"Please, choose menu for guest 5"},{"type":"plaintext"},{"type":"plaintext","content":"For those staying at the Aria hotel, it is possible to arrange parking for 895 CZK per place and night. Do you need to arrange parking at the Aria Hotel? "},{"type":"text","name":"Please write YES / NO","required":true},{"name":"Send","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"fotogalerie"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1" style={{"maxWidth":1150}} anim={"2"} animS={"5"} columns={"1"}>
            
            <ColumnWrapper >
              
              <Text className="text-box" content={"In case of further questions, please contact our wedding coordinator.\n<br>Eva: eva@ifconcept.cz"}>
              </Text>

              <Image style={{"maxWidth":""}} src={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=350x_.png 350w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=660x_.png 660w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=860x_.png 860w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=1400x_.png 1400w, https://cdn.swbpg.com/t/24903/81ba73c8a49a4f248f49d733f20a2b37_s=2000x_.png 2000w"} position={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--40" name={"paticka-2"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":1090}} anim={null} animS={null} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center fs--36 mt--10" content={"We look forward to you!"}>
              </Title>

              <Text className="text-box text-box--center mt--10" content={"<a href=\"/galerie\">Petra &amp; Petr</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}